<template>
	<div>
		<CCard>
			<CCardBody>
				<CRow>
					<CCol xl="12">
						<AdminForm @apply-form="createAdmin" @cancel-form="goToAdminList"></AdminForm>
					</CCol>
				</CRow>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
import { CREATE_ADMIN } from "./../store/action-types"
import { createNamespacedHelpers } from "vuex"
const { mapActions } = createNamespacedHelpers("admins")
export default {
	name: "CreateAdmin",

	components: {
		AdminForm: () => import("./../components/AdminForm.vue"),
	},

	props: {},

	data() {
		return {}
	},

	computed: {},

	watch: {},

	created() {},

	methods: {
		...mapActions({ CREATE_ADMIN }),

		createAdmin: async function (params) {
			await this.CREATE_ADMIN(params)
		},

		goToAdminList() {
			this.$router.push({ name: "AdminList" })
		},
	},
}
</script>

<style lang="scss" scoped></style>
